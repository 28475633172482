export function countDownTimer(dateTime, elementID, end_text_countdown) {
  const countDownTimer = document.getElementById(elementID);
  if (!countDownTimer) {
    return;
  }
  const endDate = new Date(dateTime).getTime();
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  let timer;
  function showRemaining() {
    const now = new Date().getTime();
    const remainingTime = endDate - now;
    if (remainingTime < 0) {
      clearInterval(timer);
      document.getElementById(elementID).innerHTML = end_text_countdown;
      return;
    }
    const days = Math.floor(remainingTime / day);
    const hours = Math.floor(remainingTime % day / hour);
    const minutes = Math.floor(remainingTime % hour / minute);
    const seconds = Math.floor(remainingTime % minute / second);
    let countdownValue = `${days} jours ${hours}h ${minutes}m ${seconds}s`;
    if (days < 1) {
      countdownValue = `${hours}h ${minutes}m ${seconds}s`;
    }
    document.getElementById(elementID).innerHTML = countdownValue;
  }
  timer = setInterval(showRemaining, 1000);
}
export function onUserClickEvent(fragmentElement, data) {
  fragmentElement.addEventListener("click", e => {
    if (T2S && e?.target?.nodeName === "A") {
      T2S.event('mediaClick', {
        'trackingId': data.trackingId,
        'redir': data.link
      });
    } else {
      T2S.event('mediaClick', {
        'trackingId': data.trackingId
      });
    }
    return false;
  });
}