import { countDownTimer, onUserClickEvent } from '../common/utils';
class Marketing extends lm.Composant {
  constructor(id) {
    super(id);
  }
}
lm.DOMReady(async () => {
  const fragmentElement = document.querySelector('#component-marketingfragment');
  if (fragmentElement) {
    const countDownDataElement = fragmentElement.querySelector('script.countDownTimerData');
    if (countDownDataElement) {
      const countDownTimerData = JSON.parse(countDownDataElement.innerHTML);
      if (countDownTimerData) {
        if (countDownTimerData.countDown) {
          countDownTimer(countDownTimerData.countDown, "marketing-count-down-timer", countDownTimerData.endTextCountdown);
        }
        onUserClickEvent(fragmentElement, countDownTimerData);
      }
    }
  }
});